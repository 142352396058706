import analytics from '@/services/analytics'
function sendPurchase(params) {
    const p = {
        content_ids: params.slug,
        start_date: params.startDate,
        end_date: params.endDate,
        content_category: params.bookType,
        check_in_time: params.startTime,
        check_out_time: params.endTime,
        date: analytics.getDate(params.date),
        duration_type: params.durationType,
        content_name: params.name,
        property_ID: params.slug,
        purchase_option_name: params.venueName,
        purchase_option_ID: params.venueType,
        paid_amount: params.paidAmount,
        value: params.originalAmount,
        voucher_name: analytics.getVoucherName(params.statement),
        discount: params.discount,
        payment_method: params.paymentMethod,
        currency: params.currency,
        reserve_ID: params.bookingId,
        content_type: 'hotel',
        area: params.area,
        // region: params.region,
        // city: params.city,
        // country: params.country
    }
    window.fbq('track', 'Purchase', p)
}
function sendSearch(params) {
    const p = {
        content_category: params.book_type,
        search_string: params.search_term,
        view_type: params.view_type,
        date: params.date,
        content_ids: params.content_ids,
        // region: params.region,
        // city: params.city,
        // country: params.country
    }
    window.fbq('track', 'Search', p)
}
function sendViewContent(params) {
    const p = {
        content_category: params.book_type,
        view_button_type: params.view_button_type,
        content_name: params.property_name,
        content_ids: params.item_id,
        start_date: params.start_date,
        end_date: params.end_date,
        content_type: 'hotel',
        area: params.area,
        // region: params.region,
        // city: params.city,
        // country: params.country
    }
    window.fbq('track', 'ViewContent', p)
}

function sendInitiateCheckout(params) {
    const p = {
        content_category: params.spaceType,
        check_in_time: params.checkInTime,
        check_out_time: params.checkOutTime,
        date: params.date,
        duration_type: params.bookType,
        content_name: params.name,
        content_ids: params.slug,
        property_ID: params.slug,
        purchase_option_name: params.venueName,
        purchase_option_ID: params.venue,
        start_date: params.startDate,
        end_date: params.endDate,
        // region: params.region,
        // city: params.city,
        // country: params.country
    }
    window.fbq('track', 'InitiateCheckout', p)
}

function sendAddToWishlist(name, slug, spaceType, startDate) {
    const p = {
        content_category: spaceType,
        content_name: name,
        property_ID: slug,
        content_ids: slug,
        start_date: startDate,
        end_date: '',
        content_type: 'hotel'
    }
    window.fbq('track', 'AddToWishlist', p)
}

function sendCompleteRegistration(method) {
    window.fbq('track', 'CompleteRegistration', {
        content_name: 'Registration',
        content_type: 'Registration Method',
        content_ids: [method],
        status: true
    })
}

export default {
    sendPurchase,
    sendSearch,
    sendViewContent,
    sendInitiateCheckout,
    sendAddToWishlist,
    sendCompleteRegistration
}
